
.UserEdit {
    margin-left: 15px;
    width: 40%;
}

.UserCreate {
    margin-left: 15px;
    width: 40%;
}

.txtLabel {
    margin: 16px 0 8px 0;
    color: black;
}

.TopUserListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TopUserListItem:first-child {
    color: black;
    margin-bottom: 10px;
    font-size: 14px;
}

.RequestHistoryListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

}

.ant-modal {
    width: 800px;
}

.ant-modal-root > .ant-modal-wrap > .ant-modal {
    width: 800px;
}