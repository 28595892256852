.GroupEdit {
  margin-left: 15px;
  width: 40%;
}

.GroupCreate {
  margin-left: 15px;
  width: 40%;
}

.txtLabel {
  margin: 16px 0 8px 0;
  color: black;
}
