/* LOGGING FILTER COMPONENT STYLES */
.LoggingFilter {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 0;
}

.LoggingFilterLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.space {
  width: 10px;
  height: 10px;
}

.LoggingFilterRight {}